(function ($) {
  $(window).load(function () {
    // owl-carousel
    $(".owl-carousel:not(.slider)").owlCarousel({
      items: 1,
      loop: true,

      // autoplay
      autoplay: true,
      autoplayTimeout: 7000,

      // speed
      smartSpeed: 500,

      // animation
      animateIn: "fadeIn",
      animateOut: "fadeOut",

      // navigation
      nav: true,
      navText: [
        '<i class="fas fa-chevron-left"></i>',
        '<i class="fas fa-chevron-right"></i>',
      ],
      dots: true,

      // drag
      mouseDrag: false,
    });

    // slider
    $(".home .slider:not(.reversed)").owlCarousel({
      items: 1,
      margin: 10,
      loop: true,
      // autoplay
      autoplay: true,
      autoplayTimeout: 7000,

      // speed
      smartSpeed: 500,

      // navigation
      nav: true,
      navText: [
        '<i class="fas fa-arrow-left"></i>',
        '<i class="fas fa-arrow-right"></i>',
      ],
      dots: false,

      // drag
      mouseDrag: true,

      // responsive
      responsive: {
        0: {
          items: 1.8,
        },
        576: {
          items: 1.8,
        },
        768: {
          items: 2.5,
        },
        992: {
          items: 3.5,
        },
        1200: {
          items: 3.5,
        },
      },
    });

    // reversed slider
    $(".slider.reversed").owlCarousel({
      rtl: true,
      items: 1,
      margin: 10,
      loop: true,
      // autoplay
      autoplay: true,
      autoplayTimeout: 7000,

      // speed
      smartSpeed: 500,

      // navigation
      nav: true,
      navText: [
        '<i class="fas fa-arrow-left"></i>',
        '<i class="fas fa-arrow-right"></i>',
      ],
      dots: false,

      // drag
      mouseDrag: true,

      // responsive
      responsive: {
        0: {
          items: 1.8,
        },
        576: {
          items: 1.8,
        },
        768: {
          items: 2.5,
        },
        992: {
          items: 3.5,
        },
        1200: {
          items: 3.5,
        },
      },
    });

    // slider
    $("body:not(.home) .slider:not(.reversed)").owlCarousel({
      items: 1,
      margin: 15,
      loop: true,
      lazyLoad: true,

      // autoplay
      autoplay: true,
      autoplayTimeout: 7000,

      // speed
      smartSpeed: 500,

      // navigation
      nav: true,
      navText: [
        '<i class="fas fa-arrow-left"></i>',
        '<i class="fas fa-arrow-right"></i>',
      ],
      dots: false,

      // drag
      mouseDrag: true,

      // responsive
      responsive: {
        0: {
          items: 1,
          nav: false,
          dots: true,
        },
        576: {
          items: 2,
        },
        768: {
          items: 2,
        },
        992: {
          items: 3,
        },
        1200: {
          items: 3,
        },
      },
    });

    // form-sent
    setTimeout(() => {
      var locationSearch = "" + document.location.search;
      if (
        (-1 === locationSearch.indexOf("form[sent]") &&
          -1 === decodeURIComponent(locationSearch).indexOf("form[sent]")) ||
        -1 === document.location.hash.indexOf("#form")
      ) {
        return;
      }

      var $headerHeight = $(".header");
      var $message = $(".form-success-message");

      if (!$headerHeight.length || !$message.length) {
        return;
      }

      var currentScrollTop = $("html").scrollTop();
      var newScrollTop =
        $message.offset().top - $headerHeight.outerHeight() - 30;
      if (newScrollTop >= currentScrollTop) {
        return;
      }

      $("html, body").animate({
        scrollTop: $message.offset().top - $headerHeight.outerHeight() - 30,
      });
    }, 500);
  });

  $(document).ready(function () {
    // sticky
    require("../../vendor/w3media/framework/assets/js/V2/body-sticky-class");

    // fancybox
    $("a[data-fancybox]").fancybox({
      buttons: [
        //"zoom",
        //"share",
        //"slideShow",
        //"fullScreen",
        //"download",
        "thumbs",
        "close",
      ],
    });

    // make kasabon in smooth layout sticky
    require("../../vendor/w3media/framework/assets/js/V2/hc-sticky");
    $(".w3media-booking-receipt-widget").hcSticky({
      stickTo: ".content-section",
      top: 90,
      responsive: {
        992: {
          disable: true,
        },
      },
    });

    // scroll-to-content
    $(".js-3w-scroll-to-content").on("click", function (event) {
      event.preventDefault();
      var identifier = $(this).attr("data-target");
      var $element = $(identifier);

      if ($element) {
        $("html, body").animate({ scrollTop: $element.offset().top }, 0);
      }
    });

    // faq fix
    let faqlinks = document.querySelectorAll(".faq-category a");
    faqlinks.forEach((link) => {
      if (link.id) {
        link.id = "";
        link.href = "#";
      }
    });

    // prevent scrolling on #TommyBookingSupport page
    $(window).on("hashchange", function (e) {
      if ($("#TommyBookingSupport").length > 0) {
        e.preventDefault();

        // disable scrolling
        $("body").css("overflow", "hidden");

        // set current scroll position
        $("html, body").animate({
          scrollTop: $("#TommyBookingSupport").offset().top - 120,
        });

        // enable scrolling
        setTimeout(() => {
          $("body").css("overflow", "auto");
        }, 10);
      }
    });

    // fix site-switcher link click
    $('.site-switcher .nav-link').on('click', function(e) {
      e.preventDefault();
    });
  });

  // usp list cycle

  let sections = [".home-usp-section", ".home-usp-section.two"];

  $.each(sections, function(index, section) {
    if ($(section).length > 0) {
      let $listItems = $(`${section} ul li`);
      let currentIndex = 0;

      if ($listItems.length > 0) {
        $listItems.eq(currentIndex).addClass("active");
        setInterval(function() {
          $listItems.eq(currentIndex).removeClass("active");
          currentIndex = (currentIndex + 1) % $listItems.length;
          $listItems.eq(currentIndex).addClass("active");
        }, 4500);
      }
    }
  });

  let $tabs = $("ul.tabs li");
  let currentUrl = window.location.href;
  if ($tabs.length > 0) {
    $tabs.each(function() {
      let tabUrl = $(this).find("a").attr("href");
      if (tabUrl == currentUrl) {
        $(this).addClass("active");
      }
    });
  }

})(jQuery);

// usp list cycle

// let sections = [".home-usp-section", ".home-usp-section.two"];

// sections.forEach((section) => {
//   if (section) {
//     const listItems = document.querySelectorAll(`${section} ul li`);
//     let currentIndex = 0;

//     if (listItems && listItems.length > 0) {
//       listItems[currentIndex].classList.add("active");
//       setInterval(() => {
//         listItems[currentIndex].classList.remove("active");
//         currentIndex = (currentIndex + 1) % listItems.length;
//         listItems[currentIndex].classList.add("active");
//       }, 4500);
//     }
//   }
// });

// let tabs = document.querySelectorAll("ul.tabs li");
// let currentUrl = window.location.href;
// if (tabs) {
//   tabs.forEach((tab) => {
//     let tabUrl = tab.querySelector("a").href;
//     if (tabUrl == currentUrl) {
//       tab.classList.add("active");
//     }
//   });
// }
